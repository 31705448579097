import React from 'react';
import { Link } from 'react-router-dom';
import './InternshipWrapper.css';

const InternshipWrapper = () => {
  return (
    <div className="internship-wrapper">
      <section>
      <div className="category">
        <Link to="https://rzp.io/rzp/30JYtSI" className="category-link">
          Solo  <span className="category-description"> Independent projects for self-paced learning</span>
        </Link>
      </div>
      <div className="category">
        <Link to="https://rzp.io/rzp/u7CNlSE" className="category-link">
        Team Size of 2  <span className="category-description"> Collaborative projects with peer involvement</span>
        </Link>
      </div>
      <div className="category">
        <Link to="https://rzp.io/rzp/4SDFwLln" className="category-link">
          Team Size of 3 <span className="category-description"> Collaborative projects with peer involvement</span>
        </Link>
      </div>
      <div className="category">
        <Link to="https://rzp.io/rzp/sEvt930" className="category-link">
        Team Size of 4  <span className="category-description"> Collaborative projects with peer involvement</span>
        </Link>
      </div>
      </section>
    </div>
  );
};

export default InternshipWrapper;
